<template>
  <div class="modal-container bk" @mousedown.stop="close" :class="{ hide: insufficient }">
    <form class="conversations-new modal" @mousedown.stop @submit.prevent="submit">

      <template v-if="!loading && !error">
        <h1>Start a conversation<span>{{ participants.length }}/ {{ max }}</span></h1>

        <Friends :select="select"/>

        <div class="buttons">
        <!-- Cancel -->
        <button role="button" @click.stop="close" class="submit reject" alt="Cancel">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button>

        <!-- Create -->
        <button role="button" class="submit" :class="{ disabled: !canSubmit }" alt="Create">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Create</p><span class="coin" v-if="showCoin"><img :src="icons.coin" alt="Coin"/></span><p v-if="showCoin">{{ cost }}</p></span>
        </button>
      </div>
      </template>

      <Loading v-if="loading"/>
    </form>

    <Insufficient v-if="insufficient" :close="() => this.insufficient = false" :price="this.price"/>

  </div>
</template>

<script>
import Insufficient from '@/components/shop/Insufficient.vue'
import Friends from '@/components/conversations/friends/Container.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConversationsNew',

  data() {
    return {
      loading: false,
      error: null,
      participants: [],
      insufficient: false,
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  props: {
    close: Function
  },

  components: {
    Friends,
    Loading,
    Insufficient
  },

  computed: {
    ...mapGetters( 'Limits', {
      limit: 'conversation'
    }),

    ...mapGetters( 'Conversations', {
      conversations: 'conversations'
    }),

    ...mapGetters( 'Auth', {
      me: 'id',
      balance: 'balance'
    }),

    ...mapGetters( 'Prices', {
      price: 'conversation'
    }),

    canSubmit() {
      return !this.loading && this.participants.length > 1
    },

    showCoin() {
      return this.cost && this.canSubmit
    },

    max() {
      return this.limit.participants
    },

    cost() {
      return this.price && this.participants.length > 2 ? this.$Utils.Filters.abbriviate( this.price ) : null
    }
  },

  methods: {
    ...mapActions( 'Conversations', {
      new: 'new',
      exists: 'exists'
    }),

    checkStore( participants ) {
      const c = this.conversations.find( c => {
        const ids = c.participants.map( p => p.user )
        return ids.length === participants.length && !this.$_.xor( ids, participants ).length
      })

      return c && c.id
    },

    async submit() {
      if ( !this.canSubmit ) return
      if ( this.price && this.participants.length > 2 && this.balance < this.price ) return this.insufficient = true
      const participants = this.participants

      const inStore = this.checkStore( participants )
      if ( Number.isInteger( inStore )) return this.$router.push({ name: 'conversation', params: { id: inStore }})
      
      this.loading = true
      const exists = await this.exists( participants ).catch( e => this.error = e )
      if ( Number.isInteger( exists )) return this.$router.push({ name: 'conversation', params: { id: exists }})

      //if not create it
      this.loading = true

      const id = await this.new({ participants: this.participants }).catch( e => this.error = e )
      if ( id ) return this.$router.push({ name: 'conversation', params: { id }})
      
      this.close()
    },

    select( friend ) {
      if ( this.participants.length >= this.max && !this.participants.includes( friend )) return
      this.participants = this.$_.xor( this.participants, [ friend ])
      return this.participants.includes( friend )
    }
  },

  mounted() {
    this.participants.push( this.me )
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.conversations-new {
  grid-template-rows: auto;

  h1, span {
    font-size: 16px;
    text-align: left;
    @include flex-config( false, space-between, center );
  }

  textarea {
    border-radius: 2px;
  }

  .remaining {
    font-size: 16px;
    text-align: left;
  }

  .buttons {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat( 2, 1fr );
  }
}
</style>
