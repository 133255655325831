<template>
  <li class="conversation" :title="title" @click="navigate" :class="{ temp }">

    <div class="image">
      <span class="profile" :alt="name" :style="style">
        <svg v-if="icon" v-html="icon"/>
      </span>

      <Remainder v-if="remainder" :remainder="remainder"/>
    </div>

    <div class="title"><h2>{{ title }}</h2></div>

    <div class="message">
      <p>{{ conversation.text }}</p>
      <img v-if="conversation.image" :src="icons.image" alt="Image"/>
    </div>

    <div class="date" :title="conversation.updatedAt"><p>{{ conversation.updatedAt | date }}</p></div>

  </li>
</template>

<script>
import Remainder from '@/components/conversations/Remainder.vue'

import Filters from '@/utils/filters'
import { mapGetters } from 'vuex'

export default {
  name: 'ConversationsListItem',

  data() {
    return {
      icons: {
        image: require( '@/assets/icons/shared/image.svg' )
      }
    }
  },

  props: {
    conversation: Object
  },

  components: {
    Remainder
  },

  filters: {
    ...Filters
  },

  methods: {
    navigate() {
      if ( this.temp ) return
      this.$router.push({ name: 'conversation', params: { id: this.conversation.id }})
    }
  },

  computed: {
    ...mapGetters( 'Auth', {
      me: 'id'
    }),

    //Without me
    participants() {
      const isNotMe = participant => participant.user !== this.me
      return this.conversation.participants.filter( isNotMe )
    },

    profile() {
      const people = this.participants
      if ( people.length === 1 ) return people[ 0 ]

      const recent = this.conversation.user
      const user = this.conversation.participants.find( p => p.user === recent )
      return user || people[ 0 ]
    },

    temp() {
      return this.$Utils.Validation.uuid( this.conversation.id )
    },

    style() {
      return this.$Utils.User.image( this.profile.image, this.profile.color )
    },

    name() {
      return this.$Utils.User.name( this.profile )
    },

    title() {
      const p = this.participants
      const name = u =>  this.$Utils.User.name( u )
      return p.map( o => o.name = name( o )).filter( Boolean ).join( ', ' )
    },

    icon() {
      const icon = this.profile.icon
      if ( this.profile.image || !icon ) return
      return this.$Utils.User.icon( icon )
    },

    remainder() {
      const p = this.participants
      const remainder = p.length - 1
      return remainder > 0 ? remainder : null
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

@mixin size( $size ) {
  width: $size;
  min-width: $size;
  height: $size;
}

.conversation {
  width: 100%;
  padding: 18px 32px;
  position: relative;
  display: grid;
  grid-template-areas: "image name date" "image message message";
  grid-template-columns: auto 1fr auto;
  grid-gap: 6px 24px;
  cursor: pointer;

  @media ( max-width: $tablet ) {
    grid-gap: 6px 16px;
    padding: 16px;
  }

  @media ( max-width: $mobile ) {
    grid-gap: 6px 8px;
  }

  &:not( :first-child ) {
    &::after {
      position: absolute;
      content: '';
      left: 32px;
      right: 32px;
      height: 1px;
      background-color: $med-light-grey;
    }
  }

  &:not( .temp ) {
    &:hover {
      background-color: rgba( $hover, $opacity-high );
    }
  }

  &.temp {
    opacity: $opacity-half;
    pointer-events: none;
  }

  .image {
    grid-area: image;
    @include flex-config( false, flex-start, center );

    //Profile picture
    span {
      @include size( 56px );
      grid-area: image;
      margin: 0;

      @media ( max-width: $tablet ) {
        @include size( 42px );
      }

      svg {
        @include size( 24px );

        @media ( max-width: $tablet ) {
          @include size( 18px );
        }
      }
    }

    :not( :first-child ) {
      //Overlap
      margin-left: -24px;
      width: 56px;
      min-width: 56px;
      height: 56px;
    }
  }

  .title {
    grid-area: name;
    @include flex-config( false, flex-start, center );
    overflow: hidden;

    h2 {
      text-align: left;
      @include ellipsis;

      @media ( max-width: $mobile ) {
        font-size: 14px;
      }
    }
  }

  .date {
    grid-area: date;
    @include flex-config( false, flex-end, center );

    p {
      @media ( max-width: $tablet ) {
        font-size: 12px;
      }

      @media ( max-width: $mobile ) {
        font-size: 10px;
      }
    }
  }

  .message {
    grid-area: message;
    text-align: left;
    @include flex-config( false, flex-start, flex-start );

    p {
      @include ellipsis;

      @media ( max-width: $mobile ) {
        font-size: 12px;
      }
    }

    //Image icon
    img {
      width: 18px;
      height: 18px;
      margin-left: 8px;
    }
  }
}
</style>
