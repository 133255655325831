<template>
  <div ref="scroller" v-on:scroll="scrolling" class="scroller">
    
    <ul v-if="showFriends" class="conversations-list">
      <Friend v-for="friend in friends" :key="friend.id" :friend="friend" :select="select"/>
    </ul>

    <Loading v-if="!showFriends && loading"/>

    <button role="button" @click="load" v-if="showLoadMore" alt="Load More" class="pagination" :class="{ loading }">
      <p>{{ label }}</p>
      <img v-if="!loading" :src="icons.load" alt="Load more"/>
    </button>

  </div>
</template>

<script>
import Friend from '@/components/conversations/friends/Friend.vue'
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConversationsFriends',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      icons: {
        load: require( '@/assets/icons/shared/expand.svg' )
      }
    }
  },

  props: {
    select: Function
  },

  components: {
    Loading,
    Friend
  },

  computed: {
    ...mapGetters( 'Friends', {
      friends: 'friends',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'friends'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showFriends() {
      return !this.error && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showFriends
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Friends', {
      fetch: 'get'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const count = await this.fetch({ state: 'ACCEPTED', offset: this.offset }).catch( e => this.error = e )

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error
      
      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    }
  },

  async mounted() {
    await this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.scroller {
  overflow-y: scroll;
  background-color: $off-white;
  height: 280px;
  position: relative;
  border-radius: 4px;
  display: block;

  ul {
    width: 100%;
  }

  .spinner-container {
    margin-top: 50px;
  }

  button.pagination {
    background-color: unset;
    margin: 0 auto;
    border: none;
  }
}
</style>
