<template>
  <div class="conversations" :class="{ showConversations }">
    <div ref="scroller" v-on:scroll="scrolling" class="scroller">

      <!-- Content -->
      <List v-if="showConversations"/>

      <!-- Error and Loading -->
      <div v-else class="retry">
        <div>

          <h2 v-if="showError" class="bk">{{ error || 'No messages yet' }}</h2>
          <button role="button" alt="Reload" v-if="showError" @click="reload" class="submit circle retry">
            <span class="shadow"></span>
            <span class="edge"></span>
            <span class="content"><img :src="icons.reload" alt="Reload"/></span>
          </button>
          <Loading v-if="loading"/>

        </div>
      </div>

      <!-- New Conversations modal -->
      <New v-if="modal" :close="added"/>

      <button role="button" @click="load" alt="Load More" v-if="showLoadMore" class="pagination" :class="{ loading }">
        <p>{{ label }}</p>
        <img v-if="!loading" :src="icons.load" alt="Load more"/>
      </button>

    </div>

    <!-- Create a new conversation -->
    <button role="button" v-if="showNew" alt="New" class="submit circle new" @click.stop="modal = true" title="New chat">
      <span class="shadow"></span>
      <span class="edge"></span>
      <span class="content"><img :src="icons.new" alt="Plus"/></span>
    </button>

  </div>
</template>

<script>
import List from '@/components/conversations/Container.vue'
import New from '@/components/conversations/New.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Conversations',

  data() {
    return {
      loading: false,
      loaded: false,
      canLoadMore: true,
      infScroll: true,
      error: null,
      modal: false,
      icons: {
        new: require( '@/assets/icons/shared/plus.svg' ),
        load: require( '@/assets/icons/shared/expand.svg' ),
        reload: require( '@/assets/icons/shared/reload.svg' )
      }
    }
  },

  metaInfo() {
    return {
      title: this.$router.options.title( this.$route )
    }
  },

  components: {
    List,
    New,
    Loading
  },

  computed: {
    ...mapGetters( 'Conversations', {
      isEmpty: 'isEmpty',
      count: 'count'
    }),

    ...mapGetters( 'Limits', {
      limit: 'conversations'
    }),

    ...mapGetters( 'Friends', {
      total: 'total'
    }),
    
    label() {
      return this.loading ? 'Loading' : 'Load More'
    },

    showConversations() {
      return !this.error && !this.isEmpty && this.loaded
    },

    showLoadMore() {
      return this.canLoadMore && !this.infScroll && this.showConversations
    },

    showError() {
      return ( this.error || this.isEmpty ) && !this.loading
    },

    showNew() {
      return this.loaded && this.loaded && this.total //&& !this.modal
    },

    offset() {
      return !this.loaded ? 0 : this.count
    },

    scroller() {
      return this.$refs.scroller
    }
  },

  methods: {
    ...mapActions( 'Conversations', {
      fetch: 'get'
    }),

    ...mapActions( 'Friends', {
      countFriends: 'count'
    }),

    async load() {
      if ( !this.canLoadMore || this.loading ) return

      this.error = null
      this.loading = true

      const fns = [ this.fetch({ offset: this.offset }) ]
      if ( !this.loaded ) fns.push( this.countFriends() )

      const data = await Promise.all( fns ).catch( e => this.error = e )
      const count = data[ 0 ]

      if ( !this.loaded && !this.error ) this.loaded = true
      this.loading = false

      const limit = this.limit.pagination
      this.canLoadMore = count === limit || this.error

      this.$nextTick(() => {
        this.infScroll = this.$Utils.Scrolling.infinite( this.scroller )
      })
    },

    async reload() {
      this.canLoadMore = true
      await this.load()
    },

    scrolling() {
      const check = this.scroller && this.infScroll && this.canLoadMore && !this.loading
      if ( check && this.$Utils.Scrolling.bottom( this.scroller )) this.load()
    },

    added() {
      //When a new conversations is added, close the modal and scroll to the top
      this.modal = false
      this.error = null
      if ( this.scroller ) this.scroller.scrollTop = 0
    }
  },

  async mounted() {
    this.load()
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.conversations {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  max-width: $max-width;
  margin: 0 auto;
  height: auto;
  overflow-y: hidden;

  &.showConversations {
    background: $gradient-white;
    @include box-shadow( $shadow );
  }

  .scroller {
    position: relative;
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    overflow-y: scroll;
    text-align: center;
  }

  &.shadow {
    @include box-shadow;
  }
}

button.pagination {
  margin: 16px auto;
}
</style>
