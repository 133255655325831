<template>
  <ul class="conversations-list" :class="{ hasGroupChats }">

    <Conversation v-for="conversation in conversations" :key="conversation.id" :conversation="conversation"/>

  </ul>
</template>

<script>
import Conversation from '@/components/conversations/Conversation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ConversationsList',

  components: {
    Conversation
  },

  computed: {
    ...mapGetters( 'Conversations', {
      conversations: 'conversations'
    }),

    hasGroupChats() {
      return this.conversations.findIndex( c => c.participants.length > 2 ) !== -1
    }
  }
}
</script>

<style lang="scss">
@import "@/styles/_index.scss";

.conversations-list {
  width: 100%;
  overflow-y: scroll;

  &.hasGroupChats {
    li {
      .image {
        min-width: 88px;
      }
    }
  }
}
</style>
