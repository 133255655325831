<template>
  <div class="modal-container bk" @mousedown.stop="close">
    <form class="insufficient modal" @mousedown.stop @submit.prevent>

      <img :src="icons.coin" alt="Coin"/>

      <h2>You dont have enough coins.</h2>

      <div class="buttons" :class="{ showAd }">
        <!-- Cancel -->
        <!-- <button role="button" @click.stop="close" alt="Cancel" class="submit reject">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Cancel</p></span>
        </button> -->

        <!-- Ad -->
        <button role="button" v-if="showAd" alt="Watch advertisement" @click.stop="ad" class="submit">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Watch Ad</p></span>
        </button>

        <!-- Drop -->
        <button role="button" @click.stop="shop" alt="Buy more" class="submit">
          <span class="shadow"></span>
          <span class="edge"></span>
          <span class="content"><p>Buy more</p></span>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ShopInsufficient',

  data() {
    return {
      loading: false,
      icons: {
        coin: require( '@/assets/icons/shared/coin.svg' )
      }
    }
  },

  props: {
    close: Function,
    price: Number
  },

  computed: {
    ...mapGetters( 'Auth', {
      balance: 'balance'
    }),

    showAd() {
      //TODO ad
      return false// this.price < 100
    }
  },

  methods: {
    async shop() {
      this.$router.push({ name: 'shop' })
    },

    async ad() {
      console.log( 'ad' )
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_index.scss";

.insufficient {
  grid-template-rows: repeat( 3, auto );
  min-height: 200px;
  text-align: center;

  h2 {
    padding-bottom: 16px;
  }

  img {
    width: 64px;
    height: 64px;
    margin: 0 auto;
  }

  > .buttons {
    position: relative;
    display: block;

    &.showAd {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat( 2, auto );
    }
  }
}
</style>
